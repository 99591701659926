import axios from "axios";
import { BarController, BarElement, CategoryScale, Chart, Legend, LinearScale, TimeScale, Tooltip } from 'chart.js';
import 'chartjs-adapter-moment';
import moment from "moment";
import "moment/locale/es";
import "moment/locale/gl";
import "moment/locale/pt";
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import UserService from "../../../../services/UserService";
import { formatThousands } from '../../utils/Utils';
import { IdiomContext } from "./../../../../context/createcontext";

Chart.register(BarController, BarElement, LinearScale, TimeScale, Tooltip, Legend, CategoryScale);
export default function EvolutionChart({ selectedOrganizacionId }) {
    const [lng, updateLng] = useContext(IdiomContext)
    const canvas = useRef(null);
    const legend = useRef(null);
    const [t, i18n] = useTranslation("global");
    const [selectedChart, setSelectedChart] = useState("Largo plazo");

    const [calculationEvolutions, setCalculationEvolutions] = useState({ groupedByBuilding: undefined, isFetching: true });
    const [allBuildings, setAllBuildings] = useState([]);
    const [selectedBuilding, setSelectedBuilding] = useState(null);

    useEffect(() => {
        const fetchCalculationEvolutions = async () => {
            setCalculationEvolutions({ groupedByBuilding: undefined, isFetching: true })

            let url = `${process.env.REACT_APP_CH_EVOLUTIONS}`;
            if (selectedOrganizacionId !== 'all') {
                url += `/organization/${selectedOrganizacionId}`
            }

            try {
                const response = await axios.get(`${url}`, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${UserService.getToken()}`,
                    },
                });



                let calculations = response?.data?.data || [];
                const buildingsNames = [
                    ...new Set(calculations.flatMap(calculation =>
                        calculation.calculationEvolutionBuildingDtos.map(building => building.name)
                    ))
                ];
                let groupedByBuilding = {};

                // Recorrer los datos y agruparlos por sede
                calculations.forEach(calculation => {
                    const year = calculation.year;
                    let pos = 0;
                    calculation.calculationEvolutionBuildingDtos.forEach(building => {
                        const buildingName = building.name;

                        if (!groupedByBuilding[buildingName]) {
                            groupedByBuilding[buildingName] = {
                                labels: [],
                                scope1: [],
                                scope2: [],
                                scope3: [],
                                totalScope1: [],
                                totalScope2: [],
                                totalScope3: []
                            };
                        }

                        // Si no existe la etiqueta para el año, agregarla
                        if (!groupedByBuilding[buildingName].labels.includes(year)) {
                            groupedByBuilding[buildingName].labels.push(year);
                            groupedByBuilding[buildingName].scope1.push(0);
                            groupedByBuilding[buildingName].scope2.push(0);
                            groupedByBuilding[buildingName].scope3.push(0);
                        }

                        // Agregar los datos de cada mes al array correspondiente
                        pos = groupedByBuilding[buildingName].labels.indexOf(year);
                        groupedByBuilding[buildingName].scope1[pos] = building.totalKgCO2eScope1Monthly.map(item => parseFloat(item)) || 0;
                        groupedByBuilding[buildingName].scope2[pos] = building.totalKgCO2eScope2Monthly.map(item => parseFloat(item)) || 0;
                        groupedByBuilding[buildingName].scope3[pos] = building.totalKgCO2eScope3Monthly.map(item => parseFloat(item)) || 0;

                        // Sumar los valores para el total de la sede
                        groupedByBuilding[buildingName].totalScope1[pos] = building.totalKgCO2eScope1Monthly.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        groupedByBuilding[buildingName].totalScope2[pos] = building.totalKgCO2eScope2Monthly.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                        groupedByBuilding[buildingName].totalScope3[pos] = building.totalKgCO2eScope3Monthly.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

                    });

                    //Añadimos si no hay sede
                    buildingsNames.forEach(existingBuildings => {
                        if (!groupedByBuilding[existingBuildings].labels.includes(year)) {
                            groupedByBuilding[existingBuildings].labels.push(year);
                            groupedByBuilding[existingBuildings].scope1.push(Array(12).fill(0));
                            groupedByBuilding[existingBuildings].scope2.push(Array(12).fill(0));
                            groupedByBuilding[existingBuildings].scope3.push(Array(12).fill(0));
                            groupedByBuilding[existingBuildings].totalScope1.push(0);
                            groupedByBuilding[existingBuildings].totalScope2.push(0);
                            groupedByBuilding[existingBuildings].totalScope3.push(0);
                        }
                    })
                });

                if (buildingsNames.length > 1) {
                    const allBuildingsLabel = (selectedOrganizacionId === "all" ? t("evolution.allOrganizations") : t("evolution.allOrganizationBuildings"));
                    groupedByBuilding[allBuildingsLabel] = {
                        labels: [],
                        scope1: [],
                        scope2: [],
                        scope3: [],
                        totalScope1: [],
                        totalScope2: [],
                        totalScope3: []
                    };

                    Object.keys(groupedByBuilding).forEach(building => {
                        if (building !== allBuildingsLabel) {
                            groupedByBuilding[building].labels.forEach((year, index) => {
                                if (!groupedByBuilding[allBuildingsLabel].labels.includes(year)) {
                                    groupedByBuilding[allBuildingsLabel].labels.push(year);
                                    groupedByBuilding[allBuildingsLabel].scope1.push(0);
                                    groupedByBuilding[allBuildingsLabel].scope2.push(0);
                                    groupedByBuilding[allBuildingsLabel].scope3.push(0);
                                    groupedByBuilding[allBuildingsLabel].totalScope1.push(0);
                                    groupedByBuilding[allBuildingsLabel].totalScope2.push(0);
                                    groupedByBuilding[allBuildingsLabel].totalScope3.push(0);
                                }

                                // Agregar los datos de cada mes al array correspondiente
                                let pos = groupedByBuilding[building].labels.indexOf(year);
                                groupedByBuilding[allBuildingsLabel].scope1[pos] = groupedByBuilding[building].scope1[pos].map((item, index) => {
                                    const currentItem = groupedByBuilding[building].scope1[pos][index] || 0;
                                    return parseFloat(item) + currentItem;
                                });
                                groupedByBuilding[allBuildingsLabel].scope2[pos] = groupedByBuilding[building].scope2[pos].map((item, index) => {
                                    const currentItem = groupedByBuilding[building].scope2[pos][index] || 0;
                                    return parseFloat(item) + currentItem;
                                });
                                groupedByBuilding[allBuildingsLabel].scope3[pos] = groupedByBuilding[building].scope3[pos].map((item, index) => {
                                    const currentItem = groupedByBuilding[building].scope3[pos][index] || 0;
                                    return parseFloat(item) + currentItem;
                                });

                                // Sumar los valores para el total de la sede
                                groupedByBuilding[allBuildingsLabel].totalScope1[pos] += groupedByBuilding[building].totalScope1[pos] || 0;
                                groupedByBuilding[allBuildingsLabel].totalScope2[pos] += groupedByBuilding[building].totalScope2[pos] || 0;
                                groupedByBuilding[allBuildingsLabel].totalScope3[pos] += groupedByBuilding[building].totalScope3[pos] || 0;
                            });
                        }
                    });
                    buildingsNames.unshift(allBuildingsLabel);
                }

                // Guardamos en el estado
                setCalculationEvolutions({
                    groupedByBuilding: groupedByBuilding,
                    isFetching: false
                });

                //Guardar todas las sesdes y Seleccionar una por defecto
                setAllBuildings(buildingsNames);
                setSelectedBuilding(buildingsNames[0])
            } catch (exception) {
                setCalculationEvolutions({ id: undefined, isFetching: false })
                console.error(exception);
            }
        };

        if (calculationEvolutions.labels == undefined) {
            fetchCalculationEvolutions();
        }
    }, [selectedOrganizacionId]);




    useEffect(() => {
        if (calculationEvolutions?.isFetching) return;
        let dataFormateada;
        let data;

        const selectedCalculationEvolutionBuilding = calculationEvolutions.groupedByBuilding[selectedBuilding];

        const scope1Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope1');
        const scope1LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope1-light');


        const scope2Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope2');
        const scope2LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope2-light');

        const scope3Color = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3');
        const scope3LightColor = getComputedStyle(document.documentElement).getPropertyValue('--airco2-scope3-light');

        if (selectedChart == "Corto plazo") {

            const years = selectedCalculationEvolutionBuilding?.labels?.map(year => parseInt(year));
            const firstYear = Math.min(...years);
            const currentYear = new Date().getFullYear();
            const previousYear = currentYear - 1;

            // Aaños relevantes
            let relevantYears = [firstYear, previousYear, currentYear];
            if (previousYear == firstYear) {
                relevantYears = [firstYear, currentYear];
            }

            // Crear las etiquetas para los meses
            const months = [
                "Enero", "Febrero", "Marzo", "Abril",
                "Mayo", "Junio", "Julio", "Agosto",
                "Septiembre", "Octubre", "Noviembre", "Diciembre"
            ];

            // Filtrar y mapear datos por año


            const monthlyData = relevantYears.map(year => {
                const index = years.indexOf(year);
                return {
                    year,
                    scope1: selectedCalculationEvolutionBuilding.scope1[index] || Array(12).fill(0),
                    scope2: selectedCalculationEvolutionBuilding.scope2[index] || Array(12).fill(0),
                    scope3: selectedCalculationEvolutionBuilding.scope3[index] || Array(12).fill(0),
                };
            });

            const colorScope1 = ['#f28c6a', '#e4a68e', '#ffd9b3']
            const colorScope2 = ['#ffcc33', '#ffdd66', '#ffeb99']
            const colorScope3 = ['#6699cc', '#80add6', '#b3d1ff']



            data = {
                labels: months,
                datasets: []
            }

            monthlyData.forEach((item, yearIndex) => {
                data.datasets.push({
                    label: `Alcance 1 (${item.year})`,
                    data: item.scope1,
                    backgroundColor: colorScope1[yearIndex],
                    stack: `a${item.year}`
                });
                data.datasets.push({
                    label: `Alcance 2 (${item.year})`,
                    data: item.scope2,
                    backgroundColor: colorScope2[yearIndex],
                    stack: `a${item.year}`,
                });
                data.datasets.push({
                    label: `Alcance 3 (${item.year})`,
                    data: item.scope3,
                    backgroundColor: colorScope3[yearIndex],
                    stack: `b${item.year}`,
                });
            })


        } else {

            dataFormateada = selectedCalculationEvolutionBuilding?.labels?.map((year, index) => ({
                year: year,
                alcance1: selectedCalculationEvolutionBuilding.totalScope1[index],
                alcance2: selectedCalculationEvolutionBuilding.totalScope2[index],
                alcance3: selectedCalculationEvolutionBuilding.totalScope3[index],
            }));

            data = {
                labels: dataFormateada?.map(item => item.year),
                datasets: [
                    {
                        label: t("dash.alcance1"),
                        data: dataFormateada?.map(item => item.alcance1),
                        backgroundColor: scope1Color,
                        hoverBackgroundColor: scope1LightColor,
                        stack: 'a',
                        borderWidth: 1,
                    },
                    {
                        label: t("dash.alcance2"),
                        data: dataFormateada?.map(item => item.alcance2),
                        backgroundColor: scope2Color,
                        hoverBackgroundColor: scope2LightColor,
                        stack: 'a',
                        borderWidth: 1,
                    },
                    {
                        label: t("dash.alcance3"),
                        data: dataFormateada?.map(item => item.alcance3),
                        backgroundColor: scope3Color,
                        hoverBackgroundColor: scope3LightColor,
                        stack: 'b',
                        borderWidth: 1,
                    },
                ],
            };
        }


        const ctx = canvas.current;
        // eslint-disable-next-line no-unused-vars
        switch (lng) {
            case "en":
                moment.locale("en");
                break;
            case "pt":
                moment.locale("pt");
                break;
            case "gl":
                moment.locale("gl");
                break;
            default:
                moment.locale("es");
                break;
        }
        const chart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: {
                plugins: {
                    title: {
                        display: true,
                        text: 'Chart.js Bar Chart - Stacked'
                    },

                    tooltip: {
                        callbacks: {

                            title: (context) => {
                                const buildingYear = selectedBuilding + " " + context[0].label;
                                return buildingYear;
                            },
                            label: (context) => {
                                const dataset = context.dataset;
                                const valorFormateado = `${formatThousands(context.parsed.y)} kgCO₂e`;
                                return `${dataset.label}: ${valorFormateado}`;
                            },
                        },
                    },

                    legend: {
                        display: true,
                    },
                },
                // maintainAspectRatio: false,
                responsive: true,
                interaction: {
                    intersect: false,
                },
                scales: {
                    x: {
                        stacked: true,
                        type: 'category',
                    },
                    y: {
                        stacked: true
                    }
                }
            }

        });

        return () => chart.destroy();
        // eslint-disable-next-line
    }, [lng, selectedChart, selectedBuilding]);


    return (
        <React.Fragment>
            {calculationEvolutions?.isFetching && <div className="text-center"><Spinner className='' /></div>}
            {!canvas !== null && !calculationEvolutions?.isFetching && calculationEvolutions.groupedByBuilding[selectedBuilding]?.labels?.length > 0 && <>
                <h3 className="text-center font-bold mb-2">{t("evolution.infoAlcance")}</h3>
                {allBuildings.length > 1 && <div className="mb-4">
                    <ul className="flex flex-wrap items-center justify-center -m-1">
                        {allBuildings.map((building, index) => <li className="m-1" key={`chart-building-${building}`}>
                            <button onClick={() => setSelectedBuilding(building)} className={`${selectedBuilding === building ? 'sec-green-background text-white' : ' text-gray-500'} 
                                inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank shadow-sm duration-150 ease-in-out`}>
                                {building}
                            </button>
                        </li>)}
                    </ul>
                </div>}
                <div className="mb-3">
                    <ul className="flex justify-center flex-wrap -m-1">
                        <li className="m-1">
                            <button onClick={() => setSelectedChart("Largo plazo")} className={`${selectedChart === "Largo plazo" ? 'sec-orange-background  text-white' : ' text-gray-500'}
        inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank-orange shadow-sm duration-150 ease-in-out`}>
                                Largo plazo
                            </button>
                        </li>
                        <li className="m-1">
                            <button onClick={() => setSelectedChart("Corto plazo")} className={`${selectedChart === "Corto plazo" ? 'sec-orange-background  text-white' : ' text-gray-500'}
        inline-flex items-center justify-center text-sm font-medium leading-5 px-3 py-1 rounded-sm selected-box-bank-orange shadow-sm duration-150 ease-in-out`}>
                                Corto plazo
                            </button>
                        </li>
                    </ul>

                </div>

                <canvas ref={canvas} style={{ maxHeight: "350px" }}></canvas></>}
            {!canvas !== null && !calculationEvolutions?.isFetching && calculationEvolutions.groupedByBuilding[selectedBuilding]?.labels?.length === 0 && <p className="text-center">
                Para ver esta gráfica primero es necesario que finalices algún cálculo.</p>}
        </React.Fragment>
    );


}